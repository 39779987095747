import { useForm } from "react-final-form";

import { Stack, TextField } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { COMMENT_MAX_LENGTH } from "@modules/billing/screens/validators/InvoiceItemListAddCommentValidator.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { CeaseCurrentMedicationFormValues } from "./CeaseCurrentMedicationDialog.tsx";
import { CeaseMedicationReason } from "./CeaseCurrentMedicationDialog.types.ts";

const nameOf = nameOfFactory<CeaseCurrentMedicationFormValues>();

export const CeaseCurrentMedicationFormFieldsBase: React.FC = () => {
  const { clinical } = useStores();

  const form = useForm();

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <DatePickerField
          name={nameOf("date")}
          label="Date"
          required
          maxDate={DateTime.jsDateNow()}
        />
        <Stack grow>
          <ComboBoxField
            name={nameOf("reason")}
            options={clinical.ref.ceaseMedicationReasons.keyTextValues}
            useComboBoxAsMenuWidth
            allowFreeform
            label="Reason"
            required
            placeholder="Select or type a reason"
            calloutProps={{
              calloutMaxHeight: 500
            }}
            dynamicOptions
          />
        </Stack>
      </Stack>

      <FieldCondition
        when={nameOf("reason")}
        is={CeaseMedicationReason.AdverseReaction}
      >
        <CheckboxField
          name={nameOf("openReactions")}
          label="Add to reactions"
        />
      </FieldCondition>

      <FieldSpy
        name={nameOf("reason")}
        onChange={() => {
          form.change("openReactions", false);
        }}
      />

      <Stack>
        <TextField
          name={nameOf("comment")}
          label="Comment"
          maxLength={COMMENT_MAX_LENGTH}
        />
      </Stack>
    </Stack>
  );
};

export const CeaseCurrentMedicationFormFields = withFetch(
  x => [x.clinical.ref.ceaseMedicationReasons.load()],
  CeaseCurrentMedicationFormFieldsBase
);
