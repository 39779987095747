import { observer } from "mobx-react-lite";
import { FunctionComponent, memo, useEffect } from "react";

import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { useQuickAccessContext } from "../quickAccess/context/QuickAccessContext.tsx";
import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";
import {
  PrescribingWizardContext,
  usePrescribingWizardContext
} from "./helpers/PrescribingWizardContext.tsx";
import { PrescribingWizardHelper } from "./helpers/PrescribingWizardHelper.ts";
import { PrescribingDoseForm } from "./PrescribingDoseForm.tsx";
import { PrescribingMedicationDialog } from "./PrescribingMedicationDialog.tsx";

const PrescribingWizardBase: FunctionComponent = observer(() => {
  const {
    medicationDialogVisibleWithType,
    doseFormDialogVisibleWithViewType,
    rePrescribeMed
  } = useMedicationsContext();

  const { quickAccessPrescribingRx } = useQuickAccessContext();
  const helper = usePrescribingWizardContext();

  useEffect(() => {
    if (rePrescribeMed) {
      helper.preSearchReprescribe(rePrescribeMed);
    }
  }, [helper, rePrescribeMed]);

  return (
    <>
      {((medicationDialogVisibleWithType &&
        !doseFormDialogVisibleWithViewType) ||
        quickAccessPrescribingRx) && <PrescribingMedicationDialog />}
      {doseFormDialogVisibleWithViewType && <PrescribingDoseForm />}
    </>
  );
});

const PrescribingWizardWithContext: FunctionComponent = memo(() => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  const root = useStores();
  const helper = new PrescribingWizardHelper(clinicalRecord, root);
  return (
    <PrescribingWizardContext.Provider value={helper}>
      <PrescribingWizardBase />
    </PrescribingWizardContext.Provider>
  );
});

export const PrescribingWizard = withFetch(
  x => [
    x.drugs.ref.pbsListings.load(),
    x.clinical.ref.dosingFrequencies.load(),
    x.clinical.ref.dosingAdministrationUnits.load(),
    x.clinical.ref.dosingFood.load(),
    x.clinical.ref.dosingOtherInstructions.load(),
    x.clinical.ref.dosingRoutes.load(),
    x.clinical.ref.dosingDurationPeriods.load(),
    x.clinical.ref.rxTypes.load()
  ],
  PrescribingWizardWithContext
);
